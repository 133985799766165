import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import Image from 'gatsby-image';
import { css } from 'styled-components';

import { useBackground } from '../../hooks/use-background';
import { getImage } from '../../helpers';
import {
    createShopifyCustomer,
    authenticateShopifyCustomer,
} from '~/helpers/requests/customer-requests';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { RegisterForm } from '~/components/AccountPages';
import { HeadingLarge, BodyText } from '~/components/.base/headings';
import { BackgroundDiv } from '~/components/.base/containers';

const RegisterPage = ({ location }) => {
    const backgroundImages = useBackground().edges;

    const [authErrorMessage, setAuthErrorMessage] = useState('');
    useEffect(() => {
        const accessToken = Cookies.get('KEPT_SESS');
        if (accessToken) window.location.href = '/account/dashboard';
    }, []);
    const submitForm = values => {
        createShopifyCustomer(
            values.firstName,
            values.lastName,
            values.email,
            values.password
        ).then(res => {
            if (res.data.errors && res.data.errors.length) {
                setAuthErrorMessage(res.data.errors[0].message);
                setTimeout(() => setAuthErrorMessage(''), 10000);
            } else {
                const { customerCreate } = res.data.data;
                if (!customerCreate.userErrors.length) {
                    authenticateShopifyCustomer(values.email, values.password).then(res => {
                        // set cookie with access token / exp.date

                        const { customerAccessTokenCreate } = res.data.data;
                        if (!customerAccessTokenCreate.userErrors.length) {
                            const { accessToken, expiresAt } =
                                customerAccessTokenCreate.customerAccessToken;

                            const expiryDate = new Date(expiresAt);
                            Cookies.set('KEPT_SESS', accessToken, { expires: expiryDate });
                            window.location.href = '/account/dashboard';
                        } else {
                            setAuthErrorMessage(customerAccessTokenCreate.userErrors[0].message);
                            setTimeout(() => setAuthErrorMessage(''), 10000);
                        }
                    });
                } else {
                    setAuthErrorMessage(customerCreate.userErrors[0].message);
                    setTimeout(() => setAuthErrorMessage(''), 10000);
                }
            }
        });
    };
    return (
        <div className="auth register">
            <Criteo />
            <SEO title="Register" />
            <DeriveHeaderClasses location={location} />
            <BackgroundDiv
                left="50px"
                top="150px"
                z="-1"
                css={css`
                    width: 683px;
                    max-width: 100%;
                `}
            >
                <Image fluid={getImage(backgroundImages, 'blurred_smudge')} alt="" />
            </BackgroundDiv>
            <HeadingLarge md="0 0 .75rem" align="center">
                <span className="red">Register</span>
            </HeadingLarge>
            <BodyText align="center">Please fill in the information below:</BodyText>
            <RegisterForm submitForm={submitForm} authErrorMessage={authErrorMessage} />
            <BodyText md="1rem" align="center">
                Already have an account?
                <Link to="/account/login">Login here</Link>
            </BodyText>
        </div>
    );
};

export default RegisterPage;
